var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-modify-documents",
      "size": "lg",
      "title": _vm.$t('reservation.modifyDocuments'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.submitModifyDocuments
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(_vm._s(_vm.$t('confirmation')))])])];
      }
    }])
  }, [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addDocuments[\'The current system only supports Passport\']')) + " ")]), _vm._l(_vm.paxLists, function (passenger, index) {
    return _c('div', {
      key: passenger.paxId,
      staticClass: "border-secondary rounded-lg mb-50 p-50"
    }, [_vm.documentDataToAdd && _vm.documentDataToAdd[index] ? _c('b-row', [_c('b-col', {
      staticClass: "mb-25 d-flex align-items-center",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex-center justify-content-start font-weight-bold"
    }, [_c('code', {
      staticClass: "mr-50"
    }, [_vm._v(_vm._s(_vm.$t("reservation.".concat(passenger.paxType))))]), _c('span', [_vm.getPassengerTitle(passenger) ? _c('span', [_vm._v("(" + _vm._s(_vm.getPassengerTitle(passenger)) + ")")]) : _vm._e(), _vm._v(" " + _vm._s(passenger.lastName) + " / " + _vm._s(passenger.firstName) + " ")])])]), !_vm.isEmpty(_vm.ssrDocs) ? _c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, _vm._l(_vm.ssrDocs[index], function (ssrDoc, i) {
      return _c('div', {
        key: i,
        staticClass: "my-25 mx-1",
        attrs: {
          "cols": "12"
        }
      }, [_c('small', {
        staticClass: "font-italic"
      }, [_vm._v(_vm._s(ssrDoc.ssrText))])]);
    }), 0) : _vm._e(), !_vm.isEmpty(passenger.document) ? _c('b-col', {
      staticClass: "mb-50",
      attrs: {
        "cols": "12"
      }
    }, _vm._l(_vm.getPassport(passenger), function (doc, dIndex) {
      var _vm$reservationsData, _vm$reservationsData2;
      return _c('div', {
        key: dIndex,
        staticClass: "d-flex d-flex-between"
      }, [_c('span', {
        staticClass: "fw-700"
      }, [_vm._v(" " + _vm._s("".concat(_vm.getPassport(passenger).length > 1 ? "".concat(_vm.$t('reservation.Passport'), " ").concat(dIndex + 1) : "".concat(['VJ'].includes((_vm$reservationsData = _vm.reservationsData) === null || _vm$reservationsData === void 0 ? void 0 : _vm$reservationsData.source) ? _vm.$t('reservation.ppOrCccd') : _vm.$t('reservation.Passport')))) + ": " + _vm._s(doc.number) + " " + _vm._s(doc.nationality) + " " + _vm._s(_vm.convertISODateTime(doc.endDate, 'UTC').date || doc.endDate) + " " + _vm._s(doc.isCountry))]), ['1G'].includes((_vm$reservationsData2 = _vm.reservationsData) === null || _vm$reservationsData2 === void 0 ? void 0 : _vm$reservationsData2.source) && dIndex === _vm.getPassport(passenger).length - 1 ? _c('b-button', {
        staticClass: "rounded-lg mx-25",
        attrs: {
          "variant": _vm.documentDataToAdd[index].isEdit ? 'outline-danger' : 'primary',
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            _vm.documentDataToAdd[index].isEdit = !_vm.documentDataToAdd[index].isEdit;
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.documentDataToAdd[index].isEdit ? 'Huỷ' : 'Thêm mới') + " ")]) : _vm._e()], 1);
    }), 0) : _c('b-col', {
      staticClass: "mb-50 d-flex-between",
      attrs: {
        "cols": "12"
      }
    }, [_c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation.Passport')) + ": "), _c('span', {
      staticClass: "text-danger"
    }, [_vm._v("Chưa có")])]), _c('b-button', {
      staticClass: "rounded-lg mx-25",
      attrs: {
        "variant": _vm.documentDataToAdd[index].isEdit ? 'outline-danger' : 'primary',
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          _vm.documentDataToAdd[index].isEdit = !_vm.documentDataToAdd[index].isEdit;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.documentDataToAdd[index].isEdit ? 'Huỷ' : 'Thêm mới') + " ")])], 1), _vm.documentDataToAdd[index].isEdit ? [_c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(_vm.documentDataToAdd[index].document),
        "name": _vm.$t('flight.Passport'),
        "vid": "passport-number-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-input', {
            attrs: {
              "id": "passport-number-".concat(passenger.paxType, "-").concat(index),
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "size": "sm",
              "lazy-formatter": "",
              "formatter": _vm.trimAllInputAndUpper,
              "placeholder": "".concat(_vm.$t('flight.Passport'))
            },
            model: {
              value: _vm.documentDataToAdd[index].document.number,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "number", $$v);
              },
              expression: "documentDataToAdd[index].document.number"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-number-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "window",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(_vm.documentDataToAdd[index].document),
        "name": _vm.$t('flight.Nationality'),
        "vid": "passport-nationality-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            attrs: {
              "id": "passport-nationality-".concat(passenger.paxType, "-").concat(index),
              "placeholder": _vm.$t('flight.Nationality'),
              "contextErrors": validationContext.errors[0],
              "size": 'sm'
            },
            model: {
              value: _vm.documentDataToAdd[index].document.nationality,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "nationality", $$v);
              },
              expression: "documentDataToAdd[index].document.nationality"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": !!_vm.getValidatePassport(_vm.documentDataToAdd[index].document) ? 'required' : '',
        "name": _vm.$t('flight.dob'),
        "vid": "passport-birthday-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-birthday-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: _vm.documentDataToAdd[index].document.birthday,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "birthday", $$v);
              },
              expression: "documentDataToAdd[index].document.birthday"
            }
          }), _c('div', {
            staticClass: "form-control form-control-sm p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control form-control-sm px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "passport-birthday-".concat(passenger.paxType, "-").concat(index),
              "name": _vm.$t('flight.dob'),
              "config": Object.assign({}, _vm.configFlatPickr, {
                altInputClass: 'form-control form-control-sm input px-50 py-0 border-transparent'
              }),
              "placeholder": _vm.$t('flight.dob')
            },
            model: {
              value: _vm.documentDataToAdd[index].document.birthday,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "birthday", $$v);
              },
              expression: "documentDataToAdd[index].document.birthday"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-birthday-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "window",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(_vm.documentDataToAdd[index].document),
        "name": _vm.$t('flight.placeIssue'),
        "vid": "passport-placeIssue-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            attrs: {
              "id": "passport-placeIssue-".concat(passenger.paxType, "-").concat(index),
              "placeholder": _vm.$t('flight.placeIssue'),
              "contextErrors": validationContext.errors[0],
              "size": 'sm'
            },
            model: {
              value: _vm.documentDataToAdd[index].document.isCountry,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "isCountry", $$v);
              },
              expression: "documentDataToAdd[index].document.isCountry"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(_vm.documentDataToAdd[index].document),
        "name": _vm.$t('flight.expirationDate'),
        "vid": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: _vm.documentDataToAdd[index].document.endDate,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "endDate", $$v);
              },
              expression: "documentDataToAdd[index].document.endDate"
            }
          }), _c('div', {
            staticClass: "form-control form-control-sm  p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control form-control-sm px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index),
              "name": _vm.$t('flight.expirationDate'),
              "config": Object.assign({}, _vm.configFlatPickrPassport, {
                altInputClass: 'form-control form-control-sm input px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.expirationDate'))
            },
            model: {
              value: _vm.documentDataToAdd[index].document.endDate,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "endDate", $$v);
              },
              expression: "documentDataToAdd[index].document.endDate"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "window",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(_vm.documentDataToAdd[index].document),
        "name": _vm.$t('flight.gender'),
        "vid": "passport-gender-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-gender-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('v-select', {
            staticClass: "w-100 p-0 bg-white form-control-sm",
            class: validationContext.errors[0] ? 'border-danger-select' : '',
            attrs: {
              "reduce": function reduce(val) {
                return val.value;
              },
              "label": "label",
              "options": _vm.genderOptions.filter(function (item) {
                return item.value !== 'OTHER';
              }),
              "placeholder": _vm.$t('flight.gender')
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "no-options",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
              },
              proxy: true
            }], null, true),
            model: {
              value: _vm.documentDataToAdd[index].document.gender,
              callback: function callback($$v) {
                _vm.$set(_vm.documentDataToAdd[index].document, "gender", $$v);
              },
              expression: "documentDataToAdd[index].document.gender"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-gender-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "window",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1)] : _vm._e(), _vm.isLastFlightDateAfterPassportExpiry(_vm.documentDataToAdd[index].document.endDate, _vm.flightMaxDate) ? _c('small', {
      staticClass: "text-danger mx-1 mt-25"
    }, [_vm._v(_vm._s(_vm.$t('reservation.isLastFlightDateAfterPassportExpiry')))]) : _vm.checkPassportLessThan6MonthsBeforeFlightDate(_vm.documentDataToAdd[index].document.endDate, _vm.flightMaxDate) ? _c('small', {
      staticClass: "text-danger mx-1 mt-25"
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation.passportLessThan6MonthsBeforeFlight')) + " ")]) : _vm._e()], 2) : _vm._e()], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }